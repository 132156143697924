import React from 'react';
import './patmentmethod.css'; // Import your CSS file for styling
import img from "../../assets/paypalllogo.png"

const PaymentMethods = () => {
  return (
    <div className="payment-methods">
      <h2>Payment Methods</h2>
      <div className="payment-method">
      <table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg" border="0" alt="PayPal Logo"/></a></td></tr></table>
        {/* <img
          src={img} // Replace with your PayPal logo image
          alt="PayPal"
        /> */}
      </div>
      <h3>PayPal payments</h3>

    </div>
  );
};

export default PaymentMethods;