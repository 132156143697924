import img1 from "../../../assets/Patches/Leather_Patches/1.jpeg";
import img2 from "../../../assets/Patches/Leather_Patches/2.jpeg";
import img3 from "../../../assets/Patches/Leather_Patches/3.jpeg";
import img4 from "../../../assets/Patches/Leather_Patches/4.jpeg";
import img5 from "../../../assets/Patches/Leather_Patches/5.jpeg";
// import img6 from "../../../assets/Patches/Leather_Patches/6.png";




export const lethpatchImgData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    // {
    //   image: img6,
    // },
  ];