import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./pricing.css";
const Pricing = (props) => {
  
  const [animate, setAnimate] = React.useState(false); // add this state to track animation

  React.useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".vec-price-container");
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      if (rect.top <= viewportHeight &&!animate) {
        setAnimate(true); // animate when element comes into view
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animate]);

  return (
    <>
      <h1>Pricing Plan</h1>
      <div className={`vec-price-container ${animate? "animate" : ""}`}>
        {/* <h3>$ 7-60</h3> */}
        {/* <img src={vecpimg} alt="vecprice" /> */}
        <img src={props.img} alt="vecprice" />
        <div className="vec-price-det">
          {/* <h1>Vector graphics</h1> */}
          <h1>{props.title}</h1>
          {/* we convert faster images into vector and color separations (Halftone,
          Pantone, Spot Colors, and etc)... */}
          {props.tagline}
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} /> 
            {/* We provide EPS, PDF, AI, CDR, PNG, PSD, JPG, and SVG. */}
            {props.bullet1}
          </p>
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} />
            {/* We accept all formats. */}
            {props.bullet2}
          </p>
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} />
            {/* Turnaround time: 3 to 5 hours. */}
            {props.bullet3}
          </p>
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} />
            {/* Pricing is very affordable ! */}
            {props.bullet4}
          </p>
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} />
            {/* Start's from $5 to $10. */}
            {props.bullet5}
          </p>
          <p className={`vec-price-bullet ${animate? "animate" : ""}`}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#fafafa" }} />
            {/* Further depends upon its complexity! */}
            {props.bullet6}
          </p>
        </div>
      </div>
    </>
  );
};

export default Pricing;
