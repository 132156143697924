import React, { useState, useEffect, useRef } from 'react';
import logo1 from "../../assets/softlogos/1.png"
import logo2 from "../../assets/softlogos/2.png"
import logo3 from "../../assets/softlogos/3.png"
import logo4 from "../../assets/softlogos/4.png"
import logo5 from "../../assets/softlogos/5.png"
import logo6 from "../../assets/softlogos/6.png"
import logo7 from "../../assets/softlogos/7.png"
import logo8 from "../../assets/softlogos/8.png"
import logo9 from "../../assets/softlogos/9.png"
import "./softwareweuse.css";

const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
  ];

  const SoftwareWeUse = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(5); // Initialize displayCount state
  const logoWindowRef = useRef(null); // Create a ref for the logo window

    const next = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
      };
      
      const prev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + logos.length) % logos.length);
      };

      const getLogosToDisplay = () => {
        const endIndex = currentIndex + displayCount;
        let logosToDisplay = [];
    
        if (endIndex <= logos.length) {
          logosToDisplay = logos.slice(currentIndex, endIndex);
        } else {
          // Wrap around to the start of the array
          logosToDisplay = [...logos.slice(currentIndex, logos.length), ...logos.slice(0, endIndex - logos.length)];
        }
    
        // Use the ref's current property to access the DOM element
    const containerWidth = logoWindowRef.current ? logoWindowRef.current.clientWidth : 0;
    const logoWidth = containerWidth / displayCount;
        const lastLogoIndex = (currentIndex + displayCount - 1) % logos.length;
        const lastLogoSpace = containerWidth - (logoWidth * (displayCount - 1));
    
        // If the last logo cannot be fully displayed, remove it from the array
        if (logos[lastLogoIndex].width > lastLogoSpace) {
          logosToDisplay.pop();
        }
    
        return logosToDisplay;
      };
      
      useEffect(() => {
        function updateLogoDisplayCount() {
          const screenWidth = window.innerWidth;
          let newDisplayCount;
      
          if (screenWidth <= 768) {
            newDisplayCount = 1; // Show 1 logo on small screens
          } else if (screenWidth <= 1024) {
            newDisplayCount = 2; // Show 2 logos on medium screens
          } else {
            newDisplayCount = 5; // Show 3 logos on large screens (or whatever your default is)
          }
      
          setDisplayCount(newDisplayCount); // Update the displayCount state
        }
      
        // Add event listener for window resize
    window.addEventListener('resize', updateLogoDisplayCount);

    // Call the function on initial load
    updateLogoDisplayCount();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', updateLogoDisplayCount);
      }, []); // Empty dependency array ensures this effect runs only once on mount
      
      
    return (
      <div className='logo-carousel'>
        <h2>Softwares we use</h2>
        <div className='logo-carousel-window'>
        <button className="nav-button" onClick={prev}>&lt;</button>
        <div className="logo-window">
        {getLogosToDisplay().map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index + 1}`} />
        ))}
      </div>
      <button className="nav-button" onClick={next}>&gt;</button>
      </div>
      </div>
    );
  };
  
  export default SoftwareWeUse;