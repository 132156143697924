export const VecCardData = [
  {
    title: "Logo Designing",
    description:
      "Whether you want a unique logo for your business or you are looking for a beautiful logo design pattern for a personal project.",
  },
  {
    title: "Vectorize Logo",
    description:
      "No matter what format your logo has, we can convert it into a beautiful vectorize logo through our expertise.",
  },
  {
    title: "Color Separation",
    description:
      "We know what colours are best for your vector image. That's why we allow you to edit colours and make amendments accordingly.",
  },

  {
    title: "Image Vectorization",
    description:
      "We not only specialize in logo vector art but they can also convert awe-inspiring vector images into your desired file format",
  },
  {
    title: "Print Ready Files",
    description:
      "We provide our customers with high-quality print-ready files that you can use anywhere, be it for commercial use or a personal project.",
  },
  {
    title: "JPG To Vector",
    description:
      "Convert your jpg to scalable vector graphics. We provide vector file formats at economical rates. Give us your image and get it vectorized!",
  },
];
