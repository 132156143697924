import React from "react";
import Logo from "../../assets/Logo.jpg";
// import "../../App.css";
import "./navbar.css";
// import 'react-dropdown/style.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';

const NavBar = () => {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = React.useState(false);

  const [showMenu, setShowMenu] = React.useState(false);
  const menuButtonRef = React.useRef(null);
  const menuItemsRef = React.useRef(null);
  const [menuButtonVisible, setMenuButtonVisible] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMenuButtonVisible(false);
        setShowMenu(false);
      } else {
        setMenuButtonVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleMenuButtonClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className="App-header">
        {/* <header className="App-header"> */}
        <Link to="/">
          <img src={Logo} className="App-logo" alt="logo" />
        </Link>
        <ul className="nav-ul">
          <li className={location.pathname === '/' ? 'selected-route' : ''}>
            <Link
              to="/"
            >
              Home
            </Link>
          </li>

          {/* <li className="center-text bold-nav-item">
          <Link
            to="/service"
            className={`nav-link ${
              selectedLink === "/service" ? "selected" : ""
            }`}
            onClick={() => handleLinkClick("/service")}
          >
            Our Services
          </Link>
        </li> */}
          <li className={location.pathname === '/about' ? 'selected-route' : ''}>
            <Link
              to="/about"
            >
              About Us
            </Link>
          </li>
          {/* <ul> */}
          <li
            className="menu-item"
            style={{ position: "relative" }}
            onMouseEnter={() => setShowSubMenu(true)}
            onMouseLeave={() => setShowSubMenu(false)}
          >
            Our Services
            {showSubMenu && (
              <ul className="sub-menu">
                <li className={location.pathname === '/digitizing' ? 'selected-route' : ''}>
                  <Link
                    to="/digitizing"
                  >
                    Digitizing
                  </Link>
                </li>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
                <li className={location.pathname === '/vectorart' ? 'selected-route' : ''}><Link
                    to="/vectorart"
                  >
                    Vector Art
                  </Link></li>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
                <li className={location.pathname === '/patches' ? 'selected-route' : ''}><Link
                    to="/patches"
                  >
                    Custom Patches
                  </Link></li>
              </ul>
            )}
          </li>
          {/* </ul> */}
          
          <li className={location.pathname === '/contact' ? 'selected-route' : ''}>
            <Link
              to="/contact"
            >
              Contact Us
            </Link>
          </li>
          <li className={location.pathname === '/whyus' ? 'selected-route' : ''}>
            <Link
              to="/whyus"
            >
              Why Choose Us
            </Link>
          </li>
        </ul>
        {/* </header> */}
        <button
          className="menu-button"
          ref={menuButtonRef}
          onClick={handleMenuButtonClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div>
        <ul
          className="menu-items"
          ref={menuItemsRef}
          style={{ display: showMenu ? "flex" : "none" }}
        >
          <li className={location.pathname === '/' ? 'selected-route' : ''}>
            <Link to="/">Home</Link>
          </li>
          <li className={location.pathname === '/about' ? 'selected-route' : ''}>
            <Link to="/about">About Us</Link>
          </li>
          <li className={location.pathname === '/digitizing' ? 'selected-route' : ''}>
            <Link to="/digitizing">Digitizing</Link>
          </li>
          <li className={location.pathname === '/vectorart' ? 'selected-route' : ''}>
            <Link to="/vectorart">vector Art</Link>
          </li>
          <li className={location.pathname === '/patches' ? 'selected-route' : ''}>
            <Link to="/patches">Custom patches</Link>
          </li>
          <li className={location.pathname === '/contact' ? 'selected-route' : ''}>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li className={location.pathname === '/whyus' ? 'selected-route' : ''}>
            <Link to="/whyus">Why Choose Us</Link>
          </li>
        </ul>
      </div>
      <div style={{ borderBottom: "1px solid #ccc" }}></div>
    </>
  );
};
export default NavBar;
