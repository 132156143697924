import img1 from "../../../assets/Patches/Woven_Patches/1.jpeg";
import img2 from "../../../assets/Patches/Woven_Patches/2.jpeg";
import img3 from "../../../assets/Patches/Woven_Patches/3.jpeg";




export const wovenpatchImgData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
  ];