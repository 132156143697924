import React from 'react';
import ServicesCover from '../../components/servicescover/ServicesCover';
import DigitizeProductPart from "../../components/digitizingcomponents/DigiProductPart";
import HomeCover from '../../components/homecover/homecover';
import Pricing from '../../components/pricing/pricing';
import img from "../../assets/digiprice.png"
import { useLocation } from 'react-router-dom';

const Digitizing = () => {
  const location = useLocation();

  return (
    <>
    <div>
        {/* <ServicesCover name="Digitizing"
        route={location.pathname}
        /> */}
        {/* <HomeCover/> */}
        <DigitizeProductPart />
        <Pricing
        img={img}
        title="Digitizing"
        tagline="we transform an image into an embroidery tape for use in computerized embroidery machines. We digitize your designs by keeping the final product in mind..."
          bullet1="We provide DST, EMB, DGT, PDF, and BMB"
            bullet2="We accept all formats."
            bullet3="Turnaround time: 3 to 8 hours."
            bullet4="Pricing is very affordable !"
            bullet5="Left Chest: Start's from $5 to $10."
            bullet6="Further depends upon its size!"
        />
    </div>
    </>
  );
};

export default Digitizing;