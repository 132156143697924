import React from "react";
import ServicesCover from "../../components/servicescover/ServicesCover";
// import VectorartProductPart from "../components/vectorartcomponents/Productpart";
import Patches from "../../components/patchescomponents/Patchespart";
import { useLocation } from 'react-router-dom';
import PriceList from "../../components/pricing/patchesPricing";

const PatchesPage = () => {
  const location = useLocation();

  return(
    <>
    {/* <ServicesCover name="Custom Patches" 
    route={location.pathname}
    /> */}
    {/* <HomeCover/> */}
    {/* <VectorartProductPart /> */}
    <Patches/>
    <PriceList/>

    </>
  );
};

export default PatchesPage;
