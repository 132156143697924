import React from "react";
import "./Key_Patch_Productpart.css";
import { keypatchtitle } from "./Key_Patch_prodtitle";
import { keypatchImgData } from "./Key_Patch_imagesdata";
const KeypatchPart = () => {
  return (
    <>
      <div className="KP-app-container">
        <div className="KP-card-grid">
          {keypatchtitle.map((card, index) => (
            <Card
              key={index}
              index={index + 1}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
      <div>
        {/* <h1>Keychain Patches Portfolio</h1> */}
      </div>
      <div className="KP-app-container">
        <div className="KP-card-grid-image">
          {keypatchImgData.map((card, index) => (
            <CardData key={index} {...card} />
          ))}
        </div>
      </div>
    </>
  );
};

export default KeypatchPart;

const Card = ({ title, description, index }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`KP-card ${animate ? "animate" : ""}`}>
      <div className="KP-card-content">
        <h2 className="KP-card-title">
          {index}. {title}
        </h2>
        <p className="KP-card-description">{description}</p>
      </div>
    </div>
  );
};

const CardData = ({ image }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`KP-card-img ${animate ? "animate" : ""}`}>
      <div className="KP-card-img-content">
        {image && <img src={image} alt="" className="KP-card-image" />}
      </div>
    </div>
  );
};
