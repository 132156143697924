import React from "react";
import { imageData } from "./Digiimagedata";
import { cardData } from "./Digiproductdetails";
import Pagesintro from "../pagesintro/Pagesintro";
import "./DigiProductPart.css"

const DigitizeProductPart = () => {
  return (
    <>
      <Pagesintro
        title="What we provide"
        head="Digitizing Services"
        desc="At Tom'z Design's, we provide high-quality digitized files
            optimized for all popular embroidery machines, ensuring the precise
            number of stitches, minimal thread trims, and color changes. If you
            need a reliable embroidery digitizer who offers top-notch quality,
            competitive pricing, and fast turnaround times, you've found the
            perfect partner."
      />
      <div className="digi-app-container">
        <div className="digi-card-grid">
          {cardData.map((card, index) => (
            <Card
              key={index}
              index={index + 1}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
      <div>
        <h1>Digitizing Portfolio</h1>
      </div>
      <div className="digi-app-container">
        <div className="digi-card-grid-image">
          {imageData.map((card, index) => (
            <CardData key={index} {...card} />
          ))}
        </div>
      </div>
    </>
  );
};

export default DigitizeProductPart;

const Card = ({ title, description, index }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`digi-card ${animate ? "animate" : ""}`}>
      <div className="digi-card-content">
        <h2 className="digi-card-title">
          {index}. {title}
        </h2>
        <p className="digi-card-description">{description}</p>
      </div>
    </div>
  );
};

const CardData = ({ image }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`digi-card-img ${animate ? "animate" : ""}`}>
      <div className="digi-card-img-content">
        {image && <img src={image} alt="" className="digi-card-image" />}
      </div>
    </div>
  );
};
