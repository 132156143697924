import React from "react";
import ServicesCover from "../components/servicescover/ServicesCover";
import Pagesintro from "../components/pagesintro/Pagesintro";
import logo from "../assets/Logo2.jpg";
import faceicon from "../assets/icons/faceicon.png";
import instaicon from "../assets/icons/instaicon.png";
import "./Contactus.css";
import HomeCover from "../components/homecover/homecover";
import imgcontact from "../assets/contactus.jpg"

const ContactUs = () => {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    quantity: "",
    size: "",
    machineName: "",
    additionalInformation: "",
    embroideryDigitizing: "",
    file: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      file: event.target.files[0],
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically send the formData to a backend server for processing.
    console.log(formData);
  };
  return (
    <>
      {/* <ServicesCover name="Contact Us" /> */}
      <HomeCover/>
      <Pagesintro
      img={imgcontact}
        title="Contact"
        head="get in Touch"
        desc="Feel free to visit our website and get in touch via email and our social media accounts."
      />
      <div className="contact-container">
        <div className="contact-address-container">
          <label>
            <img src={logo} alt="logo" />
          </label>
          <p></p>
          <label>
            We are very glad to have you. Got any question please feel free to
            ask?
          </label>
          <p></p>
          <label>
            Address:
            <p>California City, CA, United States</p>
          </label>
          <label>
            Email:
            <p>tomzdesigns2001@gmail.com</p>
          </label>
          {/* <label>
            Phone:
            <p>example phone</p>
          </label> */}
          <div className="social-media-lu">
            <ul>
              {/* <li> */}
              <a href="https://www.facebook.com/profile.php?id=100091345237539">
                <img src={faceicon} alt="Facebook" />
              </a>
              {/* </li> */}
              {/* <li><a href="#"><img src="twitter.svg" alt="Twitter" /></a></li>
            <li><a href="#"><img src="youtube.svg" alt="YouTube" /></a></li> */}
            </ul>
            <ul>
              {/* <li> */}
              <a href="https://www.instagram.com/tomz_designs/">
                <img src={instaicon} alt="Instagram" />
              </a>
              {/* </li> */}
            </ul>
          </div>
        </div>
        <div className="contact-form-container">
          <form onSubmit={handleSubmit}>
            <div>
              {/* <label htmlFor="name">Name:</label> */}
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              {/* <label htmlFor="name">Name:</label> */}
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="additionalInformation">Additional Information:</label> */}
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                value={formData.additionalInformation}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
