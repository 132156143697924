import Logo from "./assets/Logo.jpg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Digitizing from "./pages/Services/digitizing";
import VectorartPage from "./pages/Services/Vectorart";
import PatchesPage from "./pages/Services/Patches";
import ContactUs from "./pages/Contactus";
import Whyus from "./pages/Whyus";

function App() {
  return (
    <>
    <div className="App">
      <div>
      <NavBar/>
      </div>
      <div>
      {/* <header className="App-header">
        <img src={Logo} className="App-logo" alt="logo" />
        <ul class="nav nav-pills bold-nav" >
  <li className="center-text bold-nav-item" >
    <a href="/">Home</a>
  </li>
  <li className="center-text bold-nav-item" >
    <a href="/about">About Us</a>
  </li>
  <li className="center-text bold-nav-item">
    <a href="/service">Our Services</a>
  </li>
</ul>
      </header> */}

      {/* <Router> */}
      {/* <NavBar/> */}
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/service" element={<OurServices />} /> */}
          <Route path="/digitizing" element={<Digitizing />} />
          <Route path="/vectorart" element={<VectorartPage />} />
          <Route path="/patches" element={<PatchesPage />} />
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/whyus" element={<Whyus/>}/>
        </Routes>
      {/* </Router> */}
    </div>
    <Footer/>
    </div>
    </>
    
  );
}

export default App;
