import img1 from "../../assets/digtizedesigns/1.png";
import img2 from "../../assets/digtizedesigns/2.jpg";
import img3 from "../../assets/digtizedesigns/3.jpg";
import img4 from "../../assets/digtizedesigns/4.png";
import img5 from "../../assets/digtizedesigns/5.png";
import img6 from "../../assets/digtizedesigns/6.png";
import img7 from "../../assets/digtizedesigns/7.png";
import img8 from "../../assets/digtizedesigns/8.jpg";
import img9 from "../../assets/digtizedesigns/9.jpg";


export const imageData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
    {
      image: img7,
    },
    {
      image: img8,
    },
    {
      image: img9,
    },
    // {
    //   image: img10,
    // },
  ];