import React from "react";
import Pagesintro from "../pagesintro/Pagesintro";
import ChenipatchPart from "./Chenille_Patches/Chen_Patch_Productpart";
import KeypatchPart from "./Keychain_Patches/Key_Patch_Productpart";
import LeathpatchPart from "./Leather_Patches/Leath-Patch_Productpart";
import PVCpatchPart from "./PVC_Patches/PVC_Productpart";
import SublipatchPart from "./Sublimation_Patches/Subli_Productpart";
import WovenpatchPart from "./Woven_Patches/Woven_Productpart";

const Patches = () => {
  return (
    <>
      <Pagesintro
        title="What we provide"
        head="Custom Patches Services"
        desc="At Tom'z Design's, we craft beautiful custom patches for any purpose. Whether you're part of an organization, in the military, celebrating an event, honoring a loved one,
or just a patch enthusiast, we can create the perfect patch for you. We use premium quality threads, fabrics, and expert craftsmanship to ensure your patches are
durable and look fantastic for years to come."
      />
      <ChenipatchPart />
      <KeypatchPart />
      <LeathpatchPart/>
      <PVCpatchPart/>
      <SublipatchPart/>
      <WovenpatchPart/>
    </>
  );
};

export default Patches;
