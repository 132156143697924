import React from "react";
import ServicesCover from "../../components/servicescover/ServicesCover";
import VectorartProductPart from "../../components/vectorartcomponents/vectProductpart";
import HomeCover from "../../components/homecover/homecover";
import Pricing from '../../components/pricing/pricing';
import img from "../../assets/vectorprice.png"
import { useLocation } from 'react-router-dom';

const VectorartPage = () => {
  const location = useLocation();

  return(
    <>
    {/* <ServicesCover name="Vector Art" 
    route={location.pathname}
    /> */}
    {/* <HomeCover/> */}
    <VectorartProductPart />
    <Pricing
        img={img}
        title="Vector graphics"
        tagline="we convert faster images into vector and color separations (Halftone,
          Pantone, Spot Colors, and etc)..."
          bullet1="We provide EPS, PDF, AI, CDR, PNG, PSD, JPG, and SVG."
            bullet2="We accept all formats."
            bullet3="Turnaround time: 3 to 5 hours."
            bullet4="Pricing is very affordable !"
            bullet5="Start's from $5 to $10."
            bullet6="Further depends upon its complexity!"
        />
    </>
  );
};

export default VectorartPage;
