import img1 from "../../../assets/Patches/PVC_Patches/1.jpeg";
import img2 from "../../../assets/Patches/PVC_Patches/2.jpeg";
import img3 from "../../../assets/Patches/PVC_Patches/3.jpeg";
// import img4 from "../../../assets/Patches/PVC_Patches/4.png";




export const pvcpatchImgData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    // {
    //   image: img4,
    // },
  ];