import React from "react";
import "./Woven_Productpart.css";
import { Woven_Patchespatchtitle } from "./Woven_prodtitle";
import { wovenpatchImgData } from "./Woven_imagesdata";

const WovenpatchPart = () => {
  return (
    <>
      <div className="WP-app-container">
        <div className="WP-card-grid">
          {Woven_Patchespatchtitle.map((card, index) => (
            <Card
              key={index}
              index={index + 1}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
      <div>
        {/* <h1>Woven Patches Portfolio</h1> */}
      </div>
      <div className="WP-app-container">
        <div className="WP-card-grid-image">
          {wovenpatchImgData.map((card, index) => (
            <CardData key={index} {...card} />
          ))}
        </div>
      </div>
    </>
  );
};

export default WovenpatchPart;

const Card = ({ title, description, index }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`WP-card ${animate ? "animate" : ""}`}>
      <div className="WP-card-content">
        <h2 className="WP-card-title">
          {index}. {title}
        </h2>
        <p className="WP-card-description">{description}</p>
      </div>
    </div>
  );
};

const CardData = ({ image }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`WP-card-img ${animate ? "animate" : ""}`}>
      <div className="WP-card-img-content">
        {image && <img src={image} alt="" className="WP-card-image" />}
      </div>
    </div>
  );
};
