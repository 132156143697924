import img1 from "../../assets/vectorart/1.jpg";
import img2 from "../../assets/vectorart/2.jpg";
import img3 from "../../assets/vectorart/3.jpg";
import img4 from "../../assets/vectorart/4.jpg";
import img5 from "../../assets/vectorart/5.jpg";
import img6 from "../../assets/vectorart/6.jpg";
import img7 from "../../assets/vectorart/7.jpg";
import img8 from "../../assets/vectorart/8.jpg";
// import img9 from "../../assets/vectorart/9.jpg";
import img10 from "../../assets/vectorart/10.jpg";
import img11 from "../../assets/vectorart/11.jpg";
import img12 from "../../assets/vectorart/12.jpg";
import img13 from "../../assets/vectorart/13.jpg";
import img14 from "../../assets/vectorart/14.jpg";
import img15 from "../../assets/vectorart/15.jpg";
import img16 from "../../assets/vectorart/16.jpg";
// import img17 from "../../assets/vectorart/17.jpg";
// import img18 from "../../assets/vectorart/18.jpg";

export const VecImgData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
    {
      image: img7,
    },
    {
      image: img8,
    },
    // {
    //   image: img9,
    // },
    {
      image: img10,
    },
    {
      image: img11,
    },
    {
      image: img12,
    },
    {
      image: img13,
    },
    {
      image: img14,
    },
    {
      image: img15,
    },
    {
      image: img16,
    },
    
    // {
    //   image: img17,
    // },
    // {
    //   image: img18,
    // },
  ];