import React from "react";
import Pagesintro from "../components/pagesintro/Pagesintro";
// import ServicesCover from "../components/servicescover/ServicesCover";
import QuoteForm from "../components/getquote/GetQuote";
import HomeCover from "../components/homecover/homecover";
import imgabout from "../assets/aboutus.jpg"
import imgwhy from "../assets/whyus.jpg"

const AboutUs = () => {
  return (
    <>
      {/* <ServicesCover name="About US" /> */}
      <HomeCover />
      <Pagesintro
      img={imgabout}
        title="Who we are"
        head="About Us"
        desc="Welcome to Tom'z Design's! If you are looking for
professional embroidery digitizing, you've come to
the right place. At Tom'z Design's,
we specialize in embroidery digitizing with
nearly 18 years of experience in digitization,
vectorization, and custom patches.

Our team is dedicated to providing top-notch services,
operating 24 hours a day, 5 days a week. Equipped with
advanced technology and the latest
embroidery digitizing software,
we deliver quick, efficient, competitively priced,
and high-quality embroidery digitizing solutions."
      />
      <Pagesintro
      img2={imgwhy}
        title="Why"
        head="Why Us?"
        desc="Tom'z Design's is a premier embroidery
        digitizing and vector art company serving
        clients worldwide. With 18 years of experience,
        we deliver top-quality digitizing and vector services.
        Our skilled team is dedicated to understanding and
        executing your design requirements with precision
        and expertise.
        "
      />
      <QuoteForm/>
    </>
  );
};

export default AboutUs;
