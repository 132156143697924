import React from "react";
import "./homecover.css";
import homecover from "../../assets/homecover.jpg";

const HomeCover = (props) => {
  return (
    <>
      <div
        className="Home-Cover"
        style={{
          backgroundImage: `url(${homecover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <h1>{props.name}</h1> */}
      </div>
    </>
  );
};

export default HomeCover;
