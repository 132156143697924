import React, { useState } from 'react';
import "./GetQuote.css";
import img from "../../assets/quoteimg.png";

const QuoteForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    quantity: '',
    size: '',
    machineName: '',
    additionalInformation: '',
    embroideryDigitizing: '',
    file: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      file: event.target.files[0],
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically send the formData to a backend server for processing.
    console.log(formData);
  };

  return (
    <div className="quote-form-container">
      <div className="quote-form-image">
        <img src={img} alt="Quote Form Image" />
      </div>
      <div className="quote-form-content">
        <h2>Get A Quote</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              {/* <label htmlFor="name">Name:</label> */}
              <input
                type="text"
                id="name"
                name="name"
                placeholder='Name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="email">Email Address:</label> */}
              <input
                type="email"
                id="email"
                name="email"
                placeholder='Email Address'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              {/* <label htmlFor="phone">Phone:</label> */}
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder='Phone'
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="quantity">Quantity:</label> */}
              <input
                type="number"
                id="quantity"
                name="quantity"
                placeholder='Quantity'
                value={formData.quantity}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* <div className="form-row"> */}
            {/* <div className="form-group">
              <select
                id="size"
                name="size"
                value={formData.size}
                onChange={handleChange}
              >
                <option value="">Select Size</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div> */}
            <div className="form-group">
              {/* <label htmlFor="machineName">Machine Name / File Format:</label> */}
              <input
                type="text"
                placeholder='Machine Name / File Format:'
                id="machineName"
                name="machineName"
                value={formData.machineName}
                onChange={handleChange}
                required
              />
            </div>
          {/* </div> */}
          {/* <div className="form-row"> */}
            <div className="form-group">
              {/* <label htmlFor="additionalInformation">Additional Information:</label> */}
              <textarea
                id="additionalInformation"
                name="additionalInformation"
                placeholder='Additional Information'
                value={formData.additionalInformation}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="embroideryDigitizing">Embroidery Digitizing:</label> */}
              <select
                id="embroideryDigitizing"
                name="embroideryDigitizing"
                value={formData.embroideryDigitizing}
                onChange={handleChange}
              >
                <option value="">Select Service</option>
                <option value="digitizing">Degitizing</option>
                <option value="vector">Vector Art</option>
                <option value="patches">Custom Patches</option>
              </select>
            </div>
          {/* </div> */}
          {/* <div className="form-row"> */}
            <div className="form-group">
              {/* <label htmlFor="file">Upload Design:</label> */}
              <input
                type="file"
                id="file"
                name="file"
                placeholder='Upload Design'
                onChange={handleFileChange}
              />
            </div>
          {/* </div> */}
          <button type="submit">Get A Quote</button>
        </form>
      </div>
    </div>
  );
}

export default QuoteForm;