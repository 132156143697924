import React, { useState } from 'react';
import "./patchesPricing.css";
const PriceList = () => {
  const [selectedCategory, setSelectedCategory] = useState('EMBROIDERY');
  const [selectedQuantity, setSelectedQuantity] = useState(10);

  const categories = [
    'EMBROIDERY',
    'WOVEN',
    'CHENILLE',
    'LEATHER',
    'PVC-RABBER',
    'DYE-SUBLIMATION',
  ];

  const quantities = [10, 20, 50, 100, 500, 1000];

  const priceList = {
    EMBROIDERY: {
      10: 100,
      20: 120,
      50: 150,
      100: 170,
      500: 420,
      1000: 600,
    },
    WOVEN: {
      10: 100,
      20: 120,
      50: 180,
      100: 250,
      500: 800,
      1000: 1500,
    },
    CHENILLE: {
      10: 120,
      20: 130,
      50: 160,
      100: 220,
      500: 420,
      1000: 640,
    },
    LEATHER: {
      10: 100,
      20: 120,
      50: 150,
      100: 220,
      500: 860,
      1000: 1200,
    },
    'PVC-RABBER': {
      10: 100,
      20: 120,
      50: 150,
      100: 220,
      500: 480,
      1000: 750,
    },
    'DYE-SUBLIMATION': {
      10: 100,
      20: 120,
      50: 150,
      100: 180,
      500: 220,
      1000: 300,
    },
  };

  const getPrice = () => {
    return priceList[selectedCategory][selectedQuantity];
  };

  return (
    <div className="price-list">
      <h1>PATCHES PRICE LIST</h1>
      <div className="category-selector">
        <label htmlFor="category">Category:</label>
        <select
          id="category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className="quantity-selector">
        <label htmlFor="quantity">Quantity:</label>
        <select
          id="quantity"
          value={selectedQuantity}
          onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
        >
          {quantities.map((quantity) => (
            <option key={quantity} value={quantity}>
              {quantity}
            </option>
          ))}
        </select>
      </div>
      <div className="price">
        <h2>Price: ${getPrice()}</h2>
      </div>
    </div>
  );
};

export default PriceList;