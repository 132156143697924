import React from "react";
import ServicesCover from "../components/servicescover/ServicesCover";
import Pagesintro from "../components/pagesintro/Pagesintro";
import QuoteForm from "../components/getquote/GetQuote";
import HomeCover from "../components/homecover/homecover";

const Whyus = () => {
  return (
    <>
      {/* <ServicesCover name="Why Choose us" /> */}
      <HomeCover/>
      <Pagesintro
        head="QUICK TURNAROUND"
        desc="With years of experience in embroidery digitizing, we've honed our skills to ensure quick turnarounds. Our extensive
work with international clients has taught us to adapt to various requirements, ensuring a seamless and satisfying experience."
      />
      <Pagesintro
        head="100% SATISFACTION"
        desc="No matter the situation, our first priority is always the satisfaction of our valued clients. Our team of expert digitizers will work hard to ensure the quality of your embroidery will be second to none! We have been able to grow in the industry because of the value we place on our clientele's satisfaction"
      />
      <Pagesintro
        head="ALL FORMATS"
        desc="We know that embroidery digitizing must meet your specific needs, which is why we offer a variety of digitizing formats. Our expertise spans all types of embroidery, and you can review our work to see the quality and versatility we provide."
      />
      <Pagesintro
        head="24/7 SUPPORT"
        desc="Our outstanding customer support is available 24/7 to assist you! We guarantee prompt and satisfactory responses to all your questions and concerns. Feel free to reach out to us via email at any time."
      />
      {/* <QuoteForm /> */}
    </>
  );
};

export default Whyus;
