import React from "react";
import "./footer.css";
import "../../App.css";
import faceicon from "../../assets/icons/faceicon.png";
import instaicon from "../../assets/icons/instaicon.png";
import gmailico from "../../assets/icons/gmailicon.png";
import Logo from "../../assets/Logo2.jpg";
import footcvr from "../../assets/backgrounds/1.jpg";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  const [showFooter, setShowFooter] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.querySelector(".footer");
      const footerTop = footerElement.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;

      if (scrollPosition >= footerTop) {
        setShowFooter(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <footer
        className={`footer footer-animate ${showFooter ? "show" : ""}`}
        style={{
          backgroundImage: `url(${footcvr})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className={`footer-grid ${showFooter ? "show" : ""}`}>
          <div className={`column1 column1-animate ${showFooter ? "show" : ""}`}>
            <h3>Quick Menu</h3>
            <ul>
              <li className={location.pathname === "/" ? "selected-route" : ""}>
                <a href="/">Home</a>
              </li>
              <li
                className={
                  location.pathname === "/about" ? "selected-route" : ""
                }
              >
                <a href="about">About Us</a>
              </li>
              <li
                className={
                  location.pathname === "/contact" ? "selected-route" : ""
                }
              >
                <a href="/contact">Contact Us</a>
              </li>
              <li
                className={
                  location.pathname === "/whyus" ? "selected-route" : ""
                }
              >
                <a href="/whyus">Why choose us</a>
              </li>
            </ul>
          </div>
          <div className={`column2 column2-animate ${showFooter ? "show" : ""}`}>
            <h3>Newsletter</h3>
            <h1>Join our Newsletter</h1>
            <p>Subscribe to our newsletter for daily news and updates.</p>
            <form>
              {/* <div>
              </div> */}
              <input type="email" placeholder="Enter your email" />
              <div style={{ width: "20px" }}></div>
              <button type="submit">Subscribe</button>
            </form>
          </div>
          <div className={`column3 column3-animate ${showFooter ? "show" : ""}`}>
            <div className="logo">
              {/* <li> */}
              <a href="/">
                <img src={Logo} alt="TOMZ Designs" />
              </a>
              {/* </li> */}
            </div>
            <p>
              We believe in building long-lasting relationships with our
              customers, not just completing one-time transactions.
            </p>
            <h3>Social Media</h3>
            <div className="social-media-lu">
              <ul>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100091345237539">
                    <img src={faceicon} alt="Facebook" />
                  </a>
                </li>
                {/* <li><a href="#"><img src="twitter.svg" alt="Twitter" /></a></li>
            <li><a href="#"><img src="youtube.svg" alt="YouTube" /></a></li> */}
              </ul>
              <ul>
                <li>
                  <a href="https://www.instagram.com/tomz_designs/">
                    <img src={instaicon} alt="Instagram" />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    href={`mailto:tomzdesigns2001@gmail.com?subject=&body=`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={gmailico} alt="gmail" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        Copyright © 2024 | All rights reserved | Powered By ABC
        <a
          className={
            location.pathname === "/digitizing"
              ? "copyright-selected-route"
              : "copyright-Link"
          }
          href="/digitizing"
        >
          Digitizing
        </a>
        
        <a
          className={
            location.pathname === "/vectorart" ? "copyright-selected-route" : "copyright-Link"
          }
          href="/vectorart"
        >
          Vector art
        </a>
        <a
          className={
            location.pathname === "/patches" ? "copyright-selected-route" : "copyright-Link"
          }
          href="/patches"
        >
          Custom patches
        </a>
        {/* <a className={location.pathname === '/whyus' ? 'selected-route' : ''} href="whyus">Why choose us</a> */}
      </div>
    </>
  );
};

export default Footer;
