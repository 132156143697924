import React from "react";
import Pagesintro from "../components/pagesintro/Pagesintro";
import QuoteForm from "../components/getquote/GetQuote";
import HomeCover from "../components/homecover/homecover";
import video from "../assets/introvideo.mp4";
import "./home.css";
import PaymentMethods from "../components/paymentmethod/paymentmethod";
import SoftwareWeUse from "../components/softwareweuse/softwareweuse";
import imgabout from "../assets/aboutus.jpg";
import imgwhy from "../assets/whyus.jpg";
import AboutUs from "./AboutUs";

const Home = () => {
  const [projectCount, setProjectCount] = React.useState(0);
  const [customerCount, setCustomerCount] = React.useState(0);
  const [experienceYears, setExperienceYears] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (projectCount < 52) {
        setProjectCount(projectCount + 2);
      }
      if (customerCount < 9) {
        setCustomerCount(customerCount + 1);
      }
      if (experienceYears < 20) {
        setExperienceYears(experienceYears + 1);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [projectCount, customerCount, experienceYears]);

  return (
    <>
      <div className="home-page">
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <div className="overlay">
          <Pagesintro
            title="  Welcome to"
            head="Tomz Designs"
            desc="We believe in building long-lasting relationships with our customers, not just completing one-time transactions. Whether you need graphic design, digitizing services, or custom patches, we're committed to delivering exceptional quality and service every time."
          />
        </div>
        {/* <div className="overlay2" >
      </div> */}
      </div>
      <div className="container">
        <div className="card">
          <div className="number">{projectCount}k+</div>
          <div className="label">
            Project <br /> Completed
          </div>
        </div>
        <div className="card">
          <div className="number">{customerCount}k+</div>
          <div className="label">
            Satisfied <br /> Customer
          </div>
        </div>
        <div className="card">
          <div className="number">{experienceYears.toFixed(1)}+</div>
          <div className="label">
            Years <br /> Experience
          </div>
        </div>
      </div>
      <SoftwareWeUse />
      <PaymentMethods />

      {/* <HomeCover />
      <Pagesintro
        title="Welcome to"
        head="Tomz Design's"
        desc="We believe in building long-lasting relationships with our customers, not just completing one-time transactions. Whether you need graphic design, digitizing services, or custom patches, we're committed to delivering exceptional quality and service every time."
      /> */}
      <Pagesintro
        img={imgabout}
        title="Who we are"
        head="About Us"
        desc="Welcome to Tom'z Design's! If you are looking for
professional embroidery digitizing, you've come to
the right place. At Tom'z Design's,
we specialize in embroidery digitizing with
nearly 18 years of experience in digitization,
vectorization, and custom patches.

Our team is dedicated to providing top-notch services,
operating 24 hours a day, 5 days a week. Equipped with
advanced technology and the latest
embroidery digitizing software,
we deliver quick, efficient, competitively priced,
and high-quality embroidery digitizing solutions."
      />
      <Pagesintro
        img2={imgwhy}
        title="Why"
        head="Why Us?"
        desc="Tom'z Design's is a premier embroidery
        digitizing and vector art company serving
        clients worldwide. With 18 years of experience,
        we deliver top-quality digitizing and vector services.
        Our skilled team is dedicated to understanding and
        executing your design requirements with precision
        and expertise.
        "
      />
      <QuoteForm />
    </>
  );
};

export default Home;
