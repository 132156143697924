export const cardData = [
    {
      title: "Logo Digitizing",
      description:
        "We aim to provide quality stitching digitized file for your logo. Get logo digitizing service at affordable rates.",
    },
    {
      title: "Figurines And Merch",
      description:
        "We can digitize your design for that can be sewn on cap or foam to give it a raised look/3d look.",
    },
    {
      title: "Letter Digitizing",
      description:
        "We digitize every letter with perfection with a perfect number of stitches and pull compression. Get your free estimates now.",
    },
    {
      title: "Applique Digitizing",
      description:
        "We provide applique digitizing services where you can sew fabric along with your large design with least possible stitches.",
    },
    {
      title: "Image Digitizing",
      description:
        "We all have made drawings and images in pre-schools whether we liked them or it. An image is nothing more than a visual.",
    },
    {
      title: "Patches Digitizing",
      description:
        "Now get a fine outline for patches with perfect combination of stitches and density. Order now to get your patch digitized!",
    },
    {
      title: "Custom Digitizing",
      description:
        "Create a rough sketch and send us the picture. We will convert it into a digitized file with perfection.",
    },
    {
      title: "Left Chest Digitizing",
      description:
        'Most of the designs are at 3.5" inches for the left chest. We make sure that it stitch-out well without any compromisation on quality.',
    },
    {
      title: "Jacket Back Digitizing",
      description:
        "Get large designs for jacket back. We make sure to provide the best results in one shot so there is no second chance.",
    },
    {
      title: "Cap Digitizing",
      description:
        "Get your design digitized centered out for caps with the perfect size and stitch density. Place your order now.",
    },
    {
      title: "Pattern Digitizing",
      description:
        "We can create any pattern whether it's for dresses or laces. We make sure that every stitch speaks itself.",
    },
    {
      title: "Towel Digitizing",
      description:
        "We provide high-quality towel digitizing at low-cost rates. Get yourself registered and place your order with us today.",
    },
  ];