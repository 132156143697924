import React from "react";
import "./Subli_Productpart.css";
import {Subli_Patchespatchtitle} from "./Subli_prodtitle"
import { sublipatchImgData } from "./Subli_imagesdata";


const SublipatchPart = () => {
  return (
    <>
      <div className="SP-app-container">
        <div className="SP-card-grid">
          {Subli_Patchespatchtitle.map((card, index) => (
            <Card
              key={index}
              index={index + 1}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
      <div>
        {/* <h1>Sublimation Patches Portfolio</h1> */}
      </div>
      <div className="SP-app-container">
        <div className="SP-card-grid-image">
          {sublipatchImgData.map((card, index) => (
            <CardData key={index} {...card} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SublipatchPart;

const Card = ({ title, description, index }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`SP-card ${animate ? "animate" : ""}`}>
      <div className="SP-card-content">
        <h2 className="SP-card-title">
          {index}. {title}
        </h2>
        <p className="SP-card-description">{description}</p>
      </div>
    </div>
  );
};

const CardData = ({ image }) => {
  const [animate, setAnimate] = React.useState(false);
  const cardRef = React.createRef();

  React.useEffect(() => {
    const cardNode = cardRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(cardNode);

    return () => {
      observer.unobserve(cardNode);
    };
  }, [cardRef]);

  return (
    <div ref={cardRef} className={`SP-card-img ${animate ? "animate" : ""}`}>
      <div className="SP-card-img-content">
        {image && <img src={image} alt="" className="SP-card-image" />}
      </div>
    </div>
  );
};
