import React from "react";
import "./Pagesintro.css";
import imgabout from "../../assets/aboutus.jpg"

const Pagesintro = (props) => {

  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const containerElement = containerRef.current; // Create a variable to store the current value
  
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      },
      { threshold: 0.5 }
    );
  
    if (containerElement) {
      observer.observe(containerElement);
    }
  
    return () => {
      if (containerElement) {
        observer.unobserve(containerElement);
      }
    };
  }, [containerRef]); // Add containerRef to the dependency array
  const titleRef = React.useRef(null);
  const headingRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const imgRef = React.useRef(null);


  React.useEffect(() => {
    const titleElement = titleRef.current;
    const headingElement = headingRef.current;
    const descriptionElement = descriptionRef.current;
    const imageElement = imgRef.current;


    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (titleElement) {
      observer.observe(titleElement);
    }
    if (headingElement) {
      observer.observe(headingElement);
    }
    if (descriptionElement) {
      observer.observe(descriptionElement);
    }
    if (imageElement) {
      observer.observe(imageElement);
    }

    return () => {
      if (titleElement) {
        observer.unobserve(titleElement);
      }
      if (headingElement) {
        observer.unobserve(headingElement);
      }
      if (descriptionElement) {
        observer.unobserve(descriptionElement);
      }
      if (imageElement) {
        observer.unobserve(imageElement);
      }
    };
  }, [titleRef, headingRef, descriptionRef, imgRef]);

    return (
        <>
        {/* <div>
          <img src={imgabout} alt="about us"></img>
        </div> */}
        <div ref={containerRef} className={`page-container ${props.className}`}>
        {/* <img src={props.img} alt="img"></img> */}
        {/* {props.img && <img src={props.img} alt="img" />} */}
        {props.img && <img ref={imgRef} className="image-container" src={props.img} alt="img" style={{ borderRadius: '10px' }} />}
        <div className="page-text-content">
          <h3 ref={titleRef} className="page-title">{props.title}</h3>
          <h1 ref={headingRef} className="page-heading">
            {props.head}
            {/* <br />
            {props.head2} */}
          </h1>
        </div>
        <div ref={descriptionRef} className="page-description">
          <p>
            {props.desc}
          </p>
        </div>
        {props.img2 && <img ref={imgRef} className="image-container" src={props.img2} alt="img" style={{ borderRadius: '10px' }} />}
      </div>
        </>
    );
}

export default Pagesintro;