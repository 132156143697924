import img1 from "../../../assets/Patches/Keychain_Patches/1.jpeg";
import img2 from "../../../assets/Patches/Keychain_Patches/2.jpeg";
import img3 from "../../../assets/Patches/Keychain_Patches/3.jpeg";
// import img4 from "../../../assets/Patches/Keychain_Patches/4.jpeg";
// import img5 from "../../../assets/Patches/Keychain_Patches/5.jpeg";



export const keypatchImgData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    // {
    //   image: img4,
    // },
    // {
    //   image: img5,
    // },
  ];